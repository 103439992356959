// @flow
import * as React from 'react';

import { QuoteWrapper, Body, Author, Company } from './styles';

type Props = {
  body: string,
  author: string,
  company: string,
  theme: 'light' | 'dark',
};

const Quote = ({ body, author, company, theme }: Props) => {
  return (
    <QuoteWrapper theme={theme}>
      <Body>{body}</Body>
      <Author>{author}</Author>
      <Company>{company}</Company>
    </QuoteWrapper>
  );
};

Quote.defaultProps = {
  theme: 'light',
};

export default Quote;
