// @flow
import * as React from 'react';

import {
  TestimonialWrapper,
  ClientLogo,
  Client,
  ClientName,
  ClientServices,
  Info,
} from './styles';

type Props = {
  name: string,
  services: string,
  logoUrl: string,
  text: string,
  fullRow: boolean,
  tId: string,
  theme: 'light' | 'dark',
  isIE: boolean,
};

const Testimonial = ({
  name,
  services,
  logoUrl,
  text,
  fullRow,
  tId,
  theme,
  isIE,
}: Props) => {
  const servicesColors = {
    from: theme === 'dark' ? '#202919' : '#88B978',
    to: theme === 'dark' ? '#56804A' : '#77AA6B',
  };
  return (
    <TestimonialWrapper id={tId} theme={theme}>
      <ClientLogo logoUrl={logoUrl} theme={theme}>
        <div />
      </ClientLogo>

      <Client theme={theme}>
        <ClientName>{name}</ClientName>
        <ClientServices
          colorFrom={servicesColors.from}
          colorTo={servicesColors.to}
          isIE={isIE}
        >
          {services}
        </ClientServices>
      </Client>

      <Info fullRow={fullRow} theme={theme}>
        {text}
      </Info>
    </TestimonialWrapper>
  );
};

Testimonial.defaultProps = {
  fullRow: false,
  theme: 'light',
};

export default Testimonial;
