import styled from 'styled-components';
import { media } from '../../utils/style-utils';

import { SubHeader } from '../Styled';

export const TestimonialWrapper = styled.div`
  display: flex;
  position: relative;
  flex-wrap: wrap;
  max-width: 92%;
  color: ${({ theme }) => (theme === 'dark' ? '#000' : '#fff')};

  margin: 50px auto 100px;

  ${media.tablet`
    max-width: 450px;

    &#t0, &#t2 {
      margin: 100px auto 100px 50px;
    }
    
    &#t1, &#t3 {
      margin: 100px auto 100px auto;
    }
  `};

  ${media.desktop`
    &#t0 {
      margin: 50px auto 50px 50px;
    }
    
    &#t1 {
      margin: 50px 50px 50px auto;
    }

    &#t2 {
      margin: 50px 50px 50px auto;
    }
    
    &#t3 {
      margin: 50px auto 50px 50px;
    }
  `};
`;

export const ClientLogo = styled.div`
  width: 50px;
  height: 50px;
  min-width: 50px;
  border-radius: 50%;
  background: ${({ theme }) =>
    theme === 'dark' ? '#F8F8F8' : 'rgba(255, 255, 255, 0.45)'};
  display: flex;
  justify-content: center;
  align-items: center;

  & > div {
    background: ${({ logoUrl }) => `url(${logoUrl})`};
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    width: 80%;
    height: 80%;
  }

  ${media.tablet`
    min-width: 80px;
    width: 80px;
    height: 80px;
  `};
`;

export const Client = styled.div`
  padding-left: 8px;
  margin: 0 0 0 8px;
  border-left: ${({ theme }) =>
    `5px solid ${theme === 'dark' ? '#EBEEEA' : '#202919'}`};

  ${media.tablet`
    padding-left: 16px;
    margin: 0 0 0 16px;
  `};
`;

export const ClientName = styled.p`
  display: block;
  font-size: 16px;
  font-weight: 400;

  ${media.desktop`
    font-size: 18px;
  `};

  ${media.tablet`
    font-size: 16px;
  `};
`;

export const ClientServices = styled(SubHeader.withComponent('p'))`
  font-size: 24px;
  line-height: 1.2;
  margin: 6px 0 0;
  max-width: 220px;
  letter-spacing: 1px;

  ${media.tablet`
    max-width: 275px;
    font-size: 32px;
    margin: 6px 0 0;
  `};

  ${media.desktop`
    max-width: 320px;
    font-size: 32px;
    margin: 6px 0 0;
  `};
`;

export const Info = styled.p`
  flex: 1;
  font-size: 14px;
  max-width: 100%;
  border-left: ${({ fullRow, theme }) =>
    fullRow ? 'none' : `5px solid ${theme === 'dark' ? '#EBEEEA' : '#202919'}`};
  padding: ${({ fullRow }) => (fullRow ? '16px 0 0 0' : '16px 0 0 8px')};
  margin-left: ${({ fullRow }) => (fullRow ? '0' : '58px')};
  min-width: ${({ fullRow }) => (fullRow ? '100%;' : '50%')};

  ${media.tablet`
    min-width: 100%;
    font-size: 16px;
    max-width: 375px;
    padding: ${({ fullRow }) => (fullRow ? '16px 0 0 0' : '16px 0 0 16px')};
    margin-left: ${({ fullRow }) => (fullRow ? '0' : '96px')};
  `};
`;
