import styled from 'styled-components';
import { withPrefix } from 'gatsby';

import { media } from '../../utils/style-utils';

export const ReferencesPage = styled.article`
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

export const Section = styled.section`
  position: relative;
  display: flex;
  align-items: center;
  background: ${({ bg }) => bg};
  background-repeat: no-repeat;
  width: 100%;
  height: 500px;

  &:first-child {
    height: 570px;
  }

  &:nth-child(4) {
    height: 800px;
  }

  ${media.phone`
    &:first-child {
      height: 670px;
    }
  `};

  ${media.tablet`
    height: 1100px;

    &:first-child {
      height: 110vh;
    }

    &:nth-child(2) {
      height: 700px;
    }

    &:nth-child(4) {
      height: 1100px;
    }
  `};

  ${media.desktop`
    height: 1100px;
    
    &:first-child {
      height: 110vh;
    }

    &:nth-child(2) {
      height: 800px;
    }
  `};
`;

export const MiddleSection = styled(Section)`
  height: 450px;
  background: ${({ bg }) => bg};

  ${media.tablet`
    height: 50vh;
  `};

  ${media.desktop`
    height: 50vh;
  `};

  ${media.hd`
    height: 50vh;
  `};
`;

export const ContentWrapper = styled.div`
  position: absolute;
  width: 475px;
  height: 422px;
  right: -50px;
  bottom: 0px;

  ${media.tablet`
    width: 575px;
    height: 511px;
    right: -20px;
    bottom: 160px;
  `};

  ${media.desktop`
    width: 700px;
    height: 622px;
    bottom: 120px;
  `};

  ${media.hd`
    width: 800px;
    height: 711px;
    right: -5px;
    bottom: 50px;
  `};
`;

export const ContentImgBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: url(${withPrefix('./references/gradient_bg.svg')});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center center;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const ContentImg = styled(ContentImgBg)`
  background: url(${withPrefix('./references/gradient.svg')});
  background-repeat: no-repeat;
  background-size: contain;
  width: 95%;
  height: 95%;
  margin: auto;
  z-index: 2;
`;

export const Content = styled.div`
  position: absolute;
  top: 90px;
  left: 124px;
  z-index: 3;
  max-width: 86vw;

  ${media.phone`
    top: 100px;
    left: 85px;
    max-width: 325px;
  `};

  ${media.tablet`
    top: 120px;
    left: 100px;
    max-width: 425px;
  `};

  ${media.desktop`
    top: 150px;
    left: 130px;
    max-width: 450px;
  `};

  ${media.hd`
    top: 180px;
    left: 150px;
    max-width: 500px;
  `};
`;

export const RelativeParent = styled.div`
  position: relative;
  width: inherit;
  height: inherit;
`;

export const FlexChild = styled.div`
  flex-basis: 100%;
  min-width: 100%;
`;

export const TestimonialSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  margin 0 auto;
  height: 100%;
  padding: 20px 0;
  align-content: center;

  ${media.tablet`
    max-width: 90%;
    padding: 88px 0;
    align-content: flex-start;
  `};
  

  ${media.desktop`
    max-width: 60%;
    padding: 124px 0;

    /* &:last-of-type {
      padding: 164px 0 124px;
    } */
  `};

  ${media.hd`
    margin-bottom: 140px;
  `};
`;

export const HeaderWrapper = styled.div`
  width: 60%;
  text-align: left;
  margin: 0 32px 32px auto;

  ${media.tablet`
    width: 80%;
    text-align: right;
    margin: 0 auto;
  `};

  ${media.desktop`
    width: 100%;
    margin-bottom: 88px;
  `};
`;
